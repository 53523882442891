<template>
    <div>
        <app-layout>
            <Header
                title="Vinov Talepleri"
                    @filter-div-status="datatable.filterStatus=$event">
            </Header>
            <HeaderMobile
                title="Vinov Talepleri"
                          @filter-div-status="datatable.filterStatus=$event">
            </HeaderMobile>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="4">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('guardian_national_id')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.guardian_national_id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('status')">
                            <multi-selectbox :allow-empty="true" :multiple="false"
                                             :options="statusOptions"
                                             v-model="datatable.queryParams.filter.status"></multi-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group :label="$t('start_date')">
                            <b-input type="date"
                                     v-model="datatable.queryParams.filter.created_after">
                            </b-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="4">
                        <b-form-group :label="$t('end_date')">
                            <b-input type="date"
                                     v-model="datatable.queryParams.filter.created_before">
                            </b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                        :isLoading.sync="datatable.isLoading"
                        :columns="datatable.columns"
                        :rows="datatable.rows"
                        :total="datatable.total"
                        :queryParams="datatable.queryParams"
                        :lineNumbers="false"
                        v-show="datatable.showTable"
                        :data-function="callService">
        </datatable>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import CommonModal from "@/components/elements/CommonModal";

// Services
import VinovInstructionService from "@/services/VinovInstructionService";

import qs from 'qs'

// Page
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import ProformaInvoice from "@/services/ProformaInvoiceService";
import ReceiptService from "@/services/ReceiptService";

export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout,

        DatatableFilter,
        Datatable,
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        field: 'number',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('period'),
                        field: 'period_code',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('amount'),
                        field: 'amount',
                        formatFn: this.formatNumber,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('number_of_installments'),
                        field: 'number_of_installments',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('guardian_national_id'),
                        field: 'guardian_national_id',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('guardian_mobile_tel'),
                        field: 'guardian_mobile_tel',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('vinov_id'),
                        field: 'vinov_id',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('created_at'),
                        field: 'created_at',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
            statusOptions: [
                {value: 'Beklemede', text: 'Beklemede'},
                {value: 'Onaylandı', text: 'Onaylandı'},
                {value: 'İptal Edildi', text: 'İptal Edildi'},
                {value: 'Tamamlandı', text: 'Tamamlandı'},
            ],
        }
    },
    created() {
    },
    methods: {
        callService(config) {
            return VinovInstructionService.getAll(config)
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        }
    },
    metaInfo() {
        return {
            title: "Vinov Talepleri",
        };
    },
}
</script>
